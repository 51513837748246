<template>
  <div class="">
    <section class="lightColor pb-5 pb-lg-0 ">
      <div class="text-center">
        <b-spinner v-if="loading"></b-spinner>
      </div>
      <div class="ml-lg-15 mx-md-10 mx-10 mr-lg-15">
        <div class="p-lg-3 pr-5">
          <b-link
            to="/"
            class="font-size-12 text-capitalize"
            style="text-decoration: none"
          >
            Home / <span class="text-muted">company listing</span></b-link
          >
          <p class="text-capitalize font-size-18 lineheight mt-2">
            Top
            <span v-if="$route.params.id && !filter">{{
              $route.params.id.replaceAll("-", " ")
            }}</span
            ><span v-else> Custom Software Development </span> Companies
          </p>

          <b-card
            class="text-center mb-5"
            bg-variant="transparent"
            body-border-variant="black"
          >
            <v-row>
              <v-col lg="8" cols="12" class="text-left">
                <v-row style="justify-content: flex-start">
                  <v-col lg="2" cols="12" class="mr-lg-0 pr-lg-0">
                    <v-text-field
                      v-validate="'required'"
                      outlined
                      dense
                      name="address"
                      v-model="company.company_name"
                      hide-details
                      ref="addressInput"
                      placeholder="Company Name"
                    ></v-text-field>
                  </v-col>

                  <v-col lg="2" cols="12" class="ml-lg-0 mr-lg-0 pr-lg-0">
                    <v-select
                      v-validate="'required'"
                      name="services"
                      v-if="$route.params.id"
                      v-model="company.service"
                      outlined
                      hide-details
                      dense
                      :items="services"
                      class="select5"
                      :placeholder="$route.params.id"
                    ></v-select>
                    <v-select
                      v-else
                      v-validate="'required'"
                      name="services"
                      v-model="company.service"
                      outlined
                      hide-details
                      dense
                      :items="services"
                      class="select5"
                      placeholder="Services"
                    ></v-select>
                  </v-col>

                  <v-col lg="2" cols="12" class="ml-lg-0">
                    <v-select
                      v-validate="'required'"
                      name="hourly_rate"
                      outlined
                      v-model="company.hourly_rates"
                      hide-details
                      dense
                      :items="hourly_rate"
                      class=""
                      placeholder="Hourly Rate"
                    ></v-select>
                  </v-col>

                  <v-col lg="2" cols="12" class="ml-lg-0 pl-lg-0">
                    <v-select
                      v-validate="'required'"
                      name="reviews"
                      outlined
                      hide-details
                      v-model="company.reviews"
                      dense
                      :items="reviews"
                      class=""
                      placeholder="Ratings"
                    ></v-select>
                  </v-col>
                  <v-col lg="2" cols="12" class="ml-lg-0 pl-lg-0">
                    <v-select
                      v-validate="'required'"
                      name="Country"
                      v-model="company.country"
                      outlined
                      hide-details
                      dense
                      :items="country"
                      class="select5"
                      placeholder="Country"
                    ></v-select>
                  </v-col>
                  <v-col lg="2" cols="12" class="ml-lg-0 pl-lg-0">
                    <v-select
                      v-validate="'required'"
                      name="City"
                      v-model="company.city"
                      outlined
                      hide-details
                      dense
                      :items="city"
                      class="select5"
                      placeholder="City"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>

              <v-col lg="2" sm="4" cols="12" class="mt-2 mb-md-3 mb-lg-0 text-lg-right">
                <b-button
                  variant="dark"
                  size="md"
                  class="w-75 border-radius-8 mt-1 button-width"
                  @click="Clear()"
                  >Clear All</b-button
                >
              </v-col>
              <v-col lg="2" sm="4"  cols="12" class="mt-md-2 mb-3 mb-lg-0 text-lg-left">
                <b-button
                  variant="dark"
                  size="md"
                  class="w-50 border-radius-8 mt-1 button-width"
                  @click="companysearch"
                  >Filter</b-button
                >
              </v-col>
            </v-row>
          </b-card>
        </div>
      </div>
    </section>
    <section class="review pl-1 pr-1">
      <v-row class="">
        <v-col sm="10" class="text-left"
          ><h5 class="lineheight text-1 pt-5 pb-5 text-capitalize">
            Total {{ Company_List.length }}
            <span v-if="$route.params.id && !filter">{{
              $route.params.id.replaceAll("-", " ")
            }}</span>
            Firms
          </h5></v-col
        >
      </v-row>

      <v-card
        class="mb-5 pb-3 shadow-lg"
        v-for="(data, index) in Company_List.slice(0, NoOfCompany)"
        :key="index"
      >
        <v-row class="ml-lg-1">
          <v-col sm="1" cols="3" class="mr-lg-0 pl-7 pl-0 text-lg-right pt-5">
            <b-avatar
              variant="info"
              :src="data['company_logo']"
              size="lg"
            ></b-avatar>
          </v-col>
          <v-col sm="11" cols="9" class="ml-lg-0 text-top">
            <p class="text-2">
              {{ data["company_name"]
              }}<small class="font-size-12 third-color">
                {{ data["tag_line"] }}</small
              ><br />
              <!-- 
              <span class="sub-text" v-if="data['rating'] > 0">
                {{ data["rating"] }}
              </span> -->

              <b-form-rating
                readonly
                v-model="data['rating']"
                class="myrating pl-0 pr-0"
                inline
              >
              </b-form-rating>

              <span class="pl-0 cs-span sub-text">
                {{ data["review"] }} REVIEWS<b-avatar
                  variant="info"
                  icon="caret-right-fill"
                  size="1.5rem"
                ></b-avatar
              ></span>
            </p>
          </v-col>
        </v-row>
        <v-row class="ml-lg-0 mr-lg-0 mt-lg-0 pb-0 bordert">
          <v-col cols="12" md="2" lg="2" class="br px-0  pt-0 pt-lg-4">
            <v-list class="pt-5 pt-lg-0 bordertv bg-transparent">
              <v-list-item-group>
                <v-list-item class="para1">
                  <b-avatar
                    variant="info"
                    icon="check-circle"
                    size="1.5rem"
                  ></b-avatar>

                  <v-list-item-content class="sub-text">
                    Verified
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="para1">
                  <b-avatar variant="info" icon="tag" size="1.5rem"></b-avatar>

                  <v-list-item-content class="sub-text">
                    {{ data["min_project_size"] }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="para1">
                  <b-avatar
                    variant="info"
                    icon="clock"
                    size="1.5rem"
                  ></b-avatar>

                  <v-list-item-content class="sub-text">
                    {{ data["hourly_rate"] }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="para1 sub-text" v-if="data['address']">
                  <b-avatar
                    variant="info"
                    icon="person"
                    size="1.5rem"
                  ></b-avatar>

                  <v-list-item-content class="sub-text">
                    {{ data["address"]["employee_count"] }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="para1" v-if="data['address']">
                  <b-avatar
                    variant="info"
                    icon="geo-alt"
                    size="1.5rem"
                  ></b-avatar>

                  <v-list-item-content class="sub-text">
                    {{ data["address"]["address"] }}
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="para1 sub-text">
                  <b-avatar
                    variant="info"
                    icon="calendar4"
                    size="1.5rem"
                  ></b-avatar>

                  <v-list-item-content class="sub-text">
                    {{ data["founded_year"] }}
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col cols="12" md="5" lg="5" class="br p-lg-0">
            <div class="bordertv">
              <h6 class="pl-5 pt-5">Focus Area</h6>
              <PieChart
                class="showChart"
                :companyid="'chartdiv34' + data['id']"
                :serviceSingleChart="data['serviceLine']"
              ></PieChart>
              <Chart
                class="hideChart"
                :companyid="'chartdiv' + data['id']"
                :serviceLineFocusData="data['serviceLine']"
              ></Chart>
            </div>
          </v-col>
          <v-col cols="12" md="3" lg="3" class="br p-lg-4">
            <div class="bordertv p-4 p-lg-0">
              <p
                class="sub-text"
                v-if="data['summary'] && data['summary'].length > 200"
              >
                {{ data["summary"].substring(0, 200) + "..." }}
              </p>
              <p class="sub-text" v-else>
                {{ data["summary"] }}
              </p>
              <div class="h-50" v-if="data['user_name']">
                <span class="text-2-clr">{{ data["user_name"]["name"] }}</span
                ><br />
                <span class="sub-text mt-0">CEO & Founder</span>
              </div>
            </div></v-col
          >
          <v-col cols="12" md="2" lg="2" class="p-md-2 p-lg-2 text-center">
            <div class="bordertv p-4 p-lg-0 p-md-0">
              <v-list class="ml-15 ml-sm-0 ml-md-0 ml-lg-0  bg-transparent">
                <v-list-item
                  ><b-button
                    variant="outline-dark"
                    class="
                      button-custom-blck-company button-height-width
                      font-size-14 mb-md-3
                    "
                    @click="goToCompanyDetail(data['slug'])"
                    >View Profile</b-button
                  ></v-list-item
                >
                <v-list-item
                  ><b-button
                    variant="outline-dark"
                    class="
                      button-custom-company button-height-width
                      font-size-14
                    "
                    v-on:click="goToContact(data['id'])"
                  >
                    <!-- <router-link
                      class="contactBtn"
                      :to="'/contact-company/' + data['id']"
                      >Contact Us</router-link
                    > -->Contact Us
                  </b-button></v-list-item
                >
                <!-- <v-list-item
                ><a
                  href="#"
                  class="
                    link-comapany
                    secondary-color
                    button-height-width
                    text-center
                    font-size-14
                  "
                  >Follow Us</a
                ></v-list-item
              > -->
              </v-list>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <b-row>
        <b-col class="text-center pb-10">
          <!-- <button
            class="txt-brown font-bold "
           
          >  -->
          <v-btn
            depressed
            elevation="24"
            fab
            icon
            outlined
            raised
            color="black"
            v-if="NoOfCompany < Company_List.length"
            @click="NoOfCompany += 5"
          >
            <v-icon>mdi-plus</v-icon></v-btn
          >
          <!-- </button> -->
        </b-col>
      </b-row>
    </section>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import Chart from "../../components/Chart.vue";
import PieChart from "../../components/PieChart.vue";
export default {
  name: "listcompany",
  components: {
    Chart,
    PieChart,
  },

  data() {
    return {
      someOtherProperty: null,
      items: ["Foo", "Bar", "Fizz", "Buzz"],
      hourly_rate: [],
      industry: [],
      services: [],
      city: [],
      country: [],
      filter: false,
      rating: 0,
      review: 0,
      address: "",
      reviews: ["1+","2+","3+", "4+", "5+"],
      serviceLineFocusData: [],
      Company_List: [],
      NoOfCompany: 5,
      serviceData: [],
      loading: false,
      categoryId: "",
      company: {
        service: "",
        hourly_rates: "",
        industries: "",
        reviews: "",
        city: "",
        country: "",
        company_name: "",
      },
    };
  },

  methods: {
    ...mapActions([
      "getCompanyDetail",
      "fetchHourlyRate",
      "getCompanyCategory",
      "getIndustryList",
      "getServiceLineFocus",
      "getCompanySearch",
      "searchCompanyByName",
      "getCountry",
      "getCity",
    ]),
    goToContact(id) {
      this.userId = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : "";
      if (!this.userId) {
        this.$router.push({ name: "login" });
      } else {
        this.$router.push({ path: "/contact-company/"+id });
      }
    },
    Clear() {
      this.filter = false;
      this.company.service = "";
      this.company.hourly_rates = "";
      this.company.industries = "";
      this.company.city = "";
      this.company.country = "";
      this.company.company_name = "";
      this.company.reviews = "";
      this.getCompanyList();
    },

    async getCompanyList() {
      this.Company_List = [];
      this.loading = true;
      if (this.$route.params.id) {
        this.categoryId = this.$route.params.id;
      }

      await this.getCompanyDetail(this.categoryId)
        .then((response) => {
          this.loading = true;
          response.data.data.forEach((value) => {
            value.serviceLine = [];
            if (typeof value.id !== "undefined") {
              let serviceData = [];
              this.getServiceLineFocus({
                company_id: value.id,
                type: "list",
              }).then((res) => {
                //console.log(res.data);
                res.data.data.forEach((element) => {
                  serviceData.push({
                    category: element.categories.name,
                    value1: element.percentage,
                  });
                });
              });
              value.serviceLine = serviceData;
            }
            this.rating = 0;
            let creview = "";
            if (value.company_review.length) {
              value.company_review.forEach((res, i) => {
                creview = parseInt(i) + 1;
                this.rating = parseInt(this.rating) + parseInt(res.rating);
              });
            }

            value.rating = this.rating / value.company_review.length;
            value.review = creview;
            this.Company_List.push(value);
          });
        })
        .finally(() => (this.loading = false));
      //console.log(this.Company_List);
    },
    goToCompanyDetail: function (slug) {
      this.userId = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : "";
      if (!this.userId) {
        this.$router.push({ name: "login" });
      } else {
        this.$router.push({ name: "profile", params: { id: slug } });
      }
    },
    async getFocusData() {
      // await this.getServiceLineFocus("").then((res) => {
      //   res.data.data.forEach((element) => {
      //     this.serviceLineFocusData.push({
      //       category: element.categories.name,
      //       value1: element.percentage,
      //     });
      //   });
      // });
    },
    async fetchHourlyRateData() {
      await this.fetchHourlyRate()
        .then((response) => {
          this.loading = true;
          response.data.data.forEach((value) => {
            this.hourly_rate.push(value["name"]);
          });
        })
        .finally(() => (this.loading = false));
    },
    async getCountryName() {
      await this.getCountry()
        .then((response) => {
          this.loading = true;
          response.data.data.forEach((value) => {
            this.country.push(value["name"]);
          });
        })
        .finally(() => (this.loading = false));
    },
    async getCityName() {
      await this.getCity()
        .then((response) => {
          this.loading = true;
          response.data.data.forEach((value) => {
            this.city.push(value["name"]);
          });
        })
        .finally(() => (this.loading = false));
    },
    async getIndustryListData() {
      await this.getIndustryList()
        .then((response) => {
          this.loading = true;
          response.data.data.forEach((value) => {
            this.industry.push(value["name"]);
          });
        })
        .finally(() => (this.loading = false));
    },
    async getCompanyCategoryData() {
      await this.getCompanyCategory()
        .then((response) => {
          this.loading = true;
          response.data.data.forEach((value) => {
            if (value["parent_id"] == 0) {
              let s_id = value["id"];

              response.data.data.forEach((v) => {
                if (v["parent_id"] == s_id) {
                  this.services.push(v["name"]);
                }
              });
            }
          });
        })
        .finally(() => (this.loading = false));
    },
    companysearch() {
      this.filter = true;
      console.log(this.$route.params.id);
      this.getCompanySearch(this.company).then((response) => {
        this.Company_List = [];
        response.data.data.forEach((value) => {
          this.serviceData = [];
          if (typeof value.id !== "undefined") {
            this.getServiceLineFocus({
              company_id: value.id,
              type: "list",
            }).then((res) => {
              res.data.data.forEach((element) => {
                this.serviceData.push({
                  category: element.categories.name,
                  value1: element.percentage,
                });
              });
            });
            value.serviceLine = this.serviceData;
            let rating = 0;
            let review = "";
            if (value.company_review.length) {
              value.company_review.forEach((res, i) => {
                review = parseInt(i) + 1;
                rating =
                  (parseInt(rating) + parseInt(res.rating)) ;
              });
              rating=rating/ parseInt(value.company_review.length);
            }
            value.rating = rating;
            value.review = review;
            this.Company_List.push(value);
          }
        });
      });
    },
  },
  async created() {
    this.getCompanyList();
    setTimeout(() => {
      this.getFocusData();
      this.fetchHourlyRateData();
      this.getCountryName();
      this.getCityName();
      this.getIndustryListData();
      this.getCompanyCategoryData();
    }, 1000);
    this.loading = true;
  },
};
</script>
<style scoped>
.contactBtn {
  text-decoration: none !important;
}
.contactBtn:hover {
  color: #fff !important;
}
</style>
