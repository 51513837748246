<template>
  <div class="hello" :id="companyid" style="height: 350px"></div>
</template>
<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

export default {
  name: "Chart",
  props: ["companyid", "serviceLineFocusData"],
  data: function () {
    return {
      selected: null,
      slide: 0,
      sliding: null,
    };
  },
  mounted() {
    setTimeout(() => {
      console.log(this.companyid, this.serviceLineFocusData);
      // let id = this.companyid;
      let chart = am4core.create(this.companyid, am4charts.XYChart3D);
      am4core.options.autoSetClassName = true;

      // am4core.addLicense(this.companyid);
      chart.width = am4core.percent(100);
      if (this.serviceLineFocusData.length == 5) {
        chart.height = am4core.percent(65);
      } else {
        chart.height = am4core.percent(80);
      }
      //chart.paddingBottom = 20;
      //chart.paddingRight = 50;
      //  chart.paddingLeft = -20;
      // chart.paddingTop = -40;
      // chart.angle = 20;
      // chart.responsive.enabled = true;

      // Add data
      chart.data = this.serviceLineFocusData;

      // Create axes
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.grid.template.strokeOpacity = 0;
      categoryAxis.renderer.minGridDistance = 12;
      categoryAxis.renderer.fontSize = 14;
      categoryAxis.renderer.fontWeight = "bold";

      // let cellSize = 60;
      // chart.events.on("datavalidated", function (ev) {
      //   // Get objects of interest
      //   let chart = ev.target;
      //   let categoryAxis = chart.yAxes.getIndex(0);

      //   // Calculate how we need to adjust chart height
      //   let adjustHeight =
      //     chart.data.length * cellSize - categoryAxis.pixelHeight;

      //   // get current chart height
      //   let targetHeight = chart.pixelHeight + adjustHeight;
      //   // console.log(targetHeight);
      //   // Set it on chart's container
      //   chart.svgContainer.htmlElement.style.height = targetHeight + "px";
      // });
      // if (
      //   this.serviceLineFocusData.length == 1 ||
      //   this.serviceLineFocusData.length == 2
      // ) {
      //   console.log(this.serviceLineFocusData.length);
      //   categoryAxis.renderer.cellStartLocation = 0.2;
      //   categoryAxis.renderer.cellEndLocation = 0.8;
      //   categoryAxis.renderer.cellSpace = 0;
      // }
      // categoryAxis.renderer.marginTop=15;

      let label = categoryAxis.renderer.labels.template;
      label.wrap = true;
      label.maxWidth = 130;
      // label.marginTop = "10px";
      // label.marginBottom = "10px";
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      // if (this.serviceLineFocusData.length == 1) {
      valueAxis.renderer.grid.template.strokeOpacity = 0;
      valueAxis.min = 0;
      valueAxis.max = 100;
      valueAxis.strictMinMax = true;
      valueAxis.renderer.baseGrid.disabled = true;
      // }
      valueAxis.renderer.labels.template.adapter.add("fill", function () {
        return am4core.color("#fff");
      });
      let color = ["#955728", "#434950", "#E9E2CB", "#C49F84", "#B4B5B7"];
      // Create series
      let series1 = chart.series.push(new am4charts.ConeSeries());
      series1.dataFields.valueY = "value1";
      series1.dataFields.categoryX = "category";
      if (
        this.serviceLineFocusData.length == 1 ||
        this.serviceLineFocusData.length == 2
      ) {
        series1.columns.template.width = am4core.percent(40);
      } else if (this.serviceLineFocusData.length == 5) {
        series1.columns.template.width = am4core.percent(85);
      } else {
        series1.columns.template.width = am4core.percent(55);
      }
      series1.columns.template.strokeOpacity = 1;
      series1.columns.template.strokeWidth = 0;
      if (
        this.serviceLineFocusData.some(function (o) {
          return parseInt(o["value1"]) >= 90;
        })
      ) {
        series1.columns.template.paddingTop = "25px";
      }
      series1.calculatePercent = true;
      //series1.columns.template.paddingBottom = "150px";

      let image = new am4core.Image();
      image.horizontalCenter = "middle";
      // image.width = "30%";
      image.height = "75px";
      image.paddingTop = "22px";
      // image.verticalCenter = "middle";
      image.adapter.add("href", (href, target) => {
        let category = target.dataItem.category;
        if (category) {
          return "../image/dark.png";
        }
        return href;
      });
      // image.id = "darkArrow";
      categoryAxis.dataItems.template.bullet = image;
      categoryAxis.renderer.labels.template.adapter.add("dy", function (dy) {
        return dy + 65;
      });

      series1.columns.template.adapter.add("fill", function (fill, target) {
        if (target.dataItem.index > 4) {
          let val = color[target.dataItem.index % 5];
          return am4core.color(val);
        } else {
          return am4core.color(color[target.dataItem.index]);
        }
      });

      let bullet1 = series1.bullets.push(new am4charts.Bullet());
      let image1 = bullet1.createChild(am4core.Image);
      image1.href = "../image/round.svg";
      image1.width = 50;
      image1.height = 20;
      if (
        this.serviceLineFocusData.some(function (o) {
          return parseInt(o["value1"]) >= 90;
        })
      ) {
        bullet1.paddingTop = "25px";
      }
      image1.horizontalCenter = "middle";
      image1.verticalCenter = "middle";
      image1.zIndex = -1;

      let bullet = series1.bullets.push(new am4charts.LabelBullet());
      bullet.label.text = "{value1}%";
      bullet.label.fontSize = 12;
      if (
        this.serviceLineFocusData.some(function (o) {
          return parseInt(o["value1"]) >= 90;
        })
      ) {
        bullet.paddingTop = "25px";
      }
      //bullet.label.width = 35;
      //bullet.label.height = 15;
      bullet.label.fontWeight = "bold";
      //bullet.label.position = "absolute";
      bullet.stroke = am4core.color("#FFFFFF");
      bullet.label.zIndex = 9999;
      bullet.label.dx = 2;
      //bullet.strokeWidth = 0;
      //bullet.label.background.fill = am4core.color("#eee");
      //bullet.label.padding(0, 10, 0, 10);

      //bullet.label.background = new am4core.Circle();
      //bullet.label.background.radius = 20; //(30, 30, 30, 30);
      //bullet.label.background.padding(20, 20, 20, 20);
    }, 1000);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  methods: {
    // onSlideStart() {
    //   this.sliding = true;
    // },
    // onSlideEnd() {
    //   this.sliding = false;
    // },
  },
};
</script>
